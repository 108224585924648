@import url('https://fonts.googleapis.com/css2?family=Orbitron&family=Quicksand:wght@300;400;500;600;700&display=swap');

body {
	margin: 0;
	padding: 0;
	background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 5)),
		url('../public/background.jpg');
	font-family: 'Orbitron';
	background-size: cover;
	background-position: center;
}

.App {
	text-align: center;
}

.App-header {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(15px + 2vmin);
	letter-spacing: 0.3rem;
	color: white;
	text-shadow: 0 0 7px #fff, 0 0 10px rgb(187, 187, 187),
		     0 0 21px rgb(224, 224, 224), 0 0 42px rgb(2, 173, 116),
		     0 0 82px rgb(2, 184, 123), 0 0 92px rgb(3, 197, 132),
		     0 0 102px rgb(2, 206, 138), 0 0 151px rgb(3, 209, 141);
}
